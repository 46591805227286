import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	filterByConfluence: {
		id: 'search_dialog_filter_by_confluence',
		defaultMessage: 'Filter by {confluenceIcon} Confluence spaces, contributors and more',
		description:
			'A label for a link that takes users to the search page with Confluence filters applied',
	},
});
