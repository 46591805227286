import React, { useCallback } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { type QuickFindAllActivityDataEdge } from '@atlassian/search-client';
import {
	getGenericUrlWithAnalyticsQueryParams,
	highlightMatchedText,
} from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { ProductKeys } from '../../../../common/constants/products';
import { EventTypeMessage } from '../../../../common/ui/event-type-message';
import { ProductIcon } from '../../../../common/ui/product-icon';
import { ContentIcon } from '../../../../common/ui/quick-find/content-icon';
import {
	SearchResult,
	type SearchResultEventArguments,
} from '../../../../common/ui/quick-find/search-dialog-result';
import { type SearchMetadataPart } from '../../../../common/ui/search-metadata';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindResultEvent } from '../../../../controllers/quick-find/utils';
import { useInputQuery, useSearchSessionId } from '../../../../controllers/store';

const typeLabelContainer = xcss({
	'::first-letter': {
		textTransform: 'capitalize',
	},
});

const mapConfluenceActivityToSearchResult = (
	activity: QuickFindAllActivityDataEdge['node'],
	query: string,
) => {
	const id = activity.object.id;

	const icon = fg('rovo_search_fy25q2_visual_refresh') ? (
		<ProductIcon size="small" product={ProductKeys.Confluence} />
	) : (
		<ContentIcon type={activity.object.type} title={activity.object.data?.title} />
	);

	const title = activity.object.data?.title;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const type = [
		'confluence-type',
		<Box xcss={typeLabelContainer}>{activity.object.type}</Box>,
	] satisfies SearchMetadataPart;
	const space = activity.object.data?.space?.name || '';
	const metadata = [type, space] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = <EventTypeMessage timestamp={timestamp} eventType={eventType} />;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const urlBase = activity.object.data?.links?.base;
	const urlWebUi = activity.object.data?.links?.webUi;
	const url = urlBase && urlWebUi && `${urlBase}${urlWebUi}`;

	const spaceId = activity.object.data?.space?.id;
	const analyticsMetadata = {
		contentId: id,
		containerId: spaceId,
		contentType: `confluence ${activity.object.type}`,
	};

	if (!title || !url) {
		return undefined;
	}

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapJiraActivityToSearchResult = (
	activity: QuickFindAllActivityDataEdge['node'],
	query: string,
) => {
	const id = activity.object.id;

	const icon = fg('rovo_search_fy25q2_visual_refresh') ? (
		<ProductIcon size="small" product={ProductKeys.Jira} />
	) : (
		<ContentIcon type="jira" title={activity.object.data?.title} />
	);

	const key = activity.object.data?.key;
	const summary = activity.object.data?.fieldsById?.edges?.[0]?.node?.text;
	const title = key && summary && `${key}: ${summary}`;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const type = <Box xcss={typeLabelContainer}>{activity.object.type}</Box>;
	const metadata = [['jira-type', type]] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = <EventTypeMessage timestamp={timestamp} eventType={eventType} />;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const url = activity.object.data?.webUrl;

	const analyticsMetadata = {
		contentId: id,
		contentType: `jira ${activity.object.type}`,
	};

	if (!title || !url) {
		return undefined;
	}

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapTownsquareActivityToSearchResult = (
	activity: QuickFindAllActivityDataEdge['node'],
	query: string,
) => {
	const id = activity.object.id;

	const icon = fg('rovo_search_fy25q2_visual_refresh') ? (
		<ProductIcon size="small" product={ProductKeys.Atlas} />
	) : (
		<ContentIcon
			type="atlas"
			imageSrc={activity.object.data?.iconData || ''}
			title={activity.object.data?.title}
		/>
	);

	const title = activity.object.data?.name;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const type = [
		'type',
		<Box xcss={typeLabelContainer}>{activity.object.type}</Box>,
	] satisfies SearchMetadataPart;
	const key = activity.object.data?.key ?? null;
	const metadata = [type, key] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = <EventTypeMessage timestamp={timestamp} eventType={eventType} />;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const url = activity.object.data?.url;

	const analyticsMetadata = {
		contentId: id,
		contentType: `townsquare ${activity.object.type}`,
	};

	if (!title || !url) {
		return undefined;
	}

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

export const mapActivityToSearchResult = (
	edge: QuickFindAllActivityDataEdge,
	query: string,
	searchSessionId: string,
) => {
	const activity = edge.node;
	const product = activity.object.product;
	const result =
		product === 'confluence'
			? mapConfluenceActivityToSearchResult(activity, query)
			: product === 'jira'
				? mapJiraActivityToSearchResult(activity, query)
				: product === 'townsquare'
					? mapTownsquareActivityToSearchResult(activity, query)
					: undefined;

	if (!result) {
		return undefined;
	}

	const urlWithAnalyticsQueryParams = getGenericUrlWithAnalyticsQueryParams({
		url: result.url,
		searchSessionId,
	});

	return {
		...result,
		url: urlWithAnalyticsQueryParams,
	};
};

type Props = {
	result: QuickFindAllActivityDataEdge;
	state: State;
	onClick?: (props: QuickFindResultEvent) => void;
	onContextMenu?: (props: QuickFindResultEvent) => void;
	onHighlighted?: (props: QuickFindResultEvent) => void;
};

export const RecentActivity = ({ result, state, onClick, onContextMenu, onHighlighted }: Props) => {
	const [searchSessionId] = useSearchSessionId();
	const [inputQuery] = useInputQuery();

	const mappedResult = mapActivityToSearchResult(result, inputQuery, searchSessionId);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onClick?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onClick, state],
	);

	const onResultContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onContextMenu?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onContextMenu, state],
	);

	const onResultHighlighted = useCallback(
		(data: SearchResultEventArguments) => {
			onHighlighted?.({
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onHighlighted, state],
	);

	if (!mappedResult) {
		return null;
	}

	return (
		<SearchResult
			{...mappedResult}
			onResultClicked={onResultClicked}
			onResultContextMenu={onResultContextMenu}
			onResultHighlighted={onResultHighlighted}
		/>
	);
};
