import {
	fetchUserRecommendations,
	TeamSearchField,
	type UserSearchItem,
} from '@atlaskit/smart-common';

import { type PrimaryProductKey } from '../../../../common/constants/products';

export const fetchPeopleAndTeamsResults = async (
	baseUrl: string | undefined,
	query: string,
	organizationId: string,
	primaryProduct: PrimaryProductKey,
	principalId: string,
	siteId: string,
) => {
	// Override townsquare with watermelon as URS does not support townsquare as a primary product
	const productKey = primaryProduct === 'townsquare' ? 'watermelon' : primaryProduct;

	const response = await fetchUserRecommendations({
		query,
		context: {
			contextType: 'quick_find',
			siteId,
			productKey,
			principalId,
			organizationId,
		},
		includeUsers: true,
		includeTeams: true,
		includeGroups: false,
		maxNumberOfResults: 2,
		performSearchQueryOnly: false,
		searchQuery: {
			filter:
				'account_status:"active" AND (NOT email_domain:"connect.atlassian.com") AND (NOT account_type:app)',
			teamSearchFields: [TeamSearchField.NAME],
		},
		baseUrl,
	});

	return response.map(mapEssentialData);
};

export const mapEssentialData = (result: UserSearchItem) => ({
	id: result.id,
	title: result.name || result.displayName || '',
	data: result,
	type: 'people-and-teams' as const,
});
