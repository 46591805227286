import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onSearchRequestCompleted = ({
	attributes,
	nonPrivacySafeAttributes,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	source: 'searchDialog',
	eventType: 'ui',
	action: 'completed',
	actionSubject: 'searchRequest',
	actionSubjectId: 'postQuerySearchResults',
	attributes,
	nonPrivacySafeAttributes,
});
