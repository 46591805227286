import React, { useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import SearchIcon from '@atlaskit/icon/core/search';
import { Inline } from '@atlaskit/primitives';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { useAppContext } from '../../../../common/ui/app-context';
import { SearchFooter } from '../../../../common/ui/quick-find/search-dialog-footer';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindFooterEvent } from '../../../../controllers/quick-find/utils';
import { useInputQuery } from '../../../../controllers/store';

import { messages } from './messages';

type Props = {
	state: State;
	onClick: (props: QuickFindFooterEvent) => void;
};

export const SearchPageLink = ({ state, onClick }: Props) => {
	const intl = useIntl();

	const { generateSearchUrl, queryParams } = useAppContext();

	const [inputQuery] = useInputQuery();

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onClick({ state, event });
		},
		[onClick, state],
	);

	const icon = (
		<SearchIcon label={intl.formatMessage(messages.searchAcrossProducts)} color="currentColor" />
	);
	const title = intl.formatMessage(messages.searchAcrossProducts);

	const rightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);
	const url = generateSearchUrl({ ...queryParams, text: inputQuery });

	return (
		<SearchFooter
			icon={icon}
			title={title}
			rightMetadata={rightMetadata}
			url={url}
			onResultClicked={onResultClicked}
		/>
	);
};
