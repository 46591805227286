import { useCallback } from 'react';

import { type GasPayload } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { DEFAULT_GAS_CHANNEL } from '../../../common/constants/analytics';
import { sha256Hash } from '../../../common/utils/events/utils';
import {
	useApplicationMode,
	useExperimentConfig,
	useInputQuery,
	useQueryUpdatedTime,
	useQueryVersion,
	useSearchSessionId,
} from '../../store';

export const useQuickFindAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAnalyticsEvent = useCallback(
		(payload: GasPayload) => {
			createAnalyticsEvent({
				...payload,
				source: 'searchDialog',
			}).fire(DEFAULT_GAS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	return { fireAnalyticsEvent };
};

export const useQuickFindAttributes = () => {
	const [searchSessionId] = useSearchSessionId();
	const [inputQuery] = useInputQuery();
	const [queryUpdatedTime] = useQueryUpdatedTime();
	const [queryVersion] = useQueryVersion();
	const [experimentConfig] = useExperimentConfig();
	const applicationMode = useApplicationMode();

	const { frontendExperiments = {}, backendExperiment } = experimentConfig;

	const commonAttributes = {
		abTest: backendExperiment,
		frontendExperiments: {
			activeExperiments: Object.keys(frontendExperiments).filter(
				(experiment) => frontendExperiments[experiment]?.serveExperiment,
			),
			...frontendExperiments,
		},
		numberOfTerms: (inputQuery.match(/\w+/g) || []).length,
		queryHash: sha256Hash(inputQuery),
		queryHashSHA256: sha256Hash(inputQuery),
		queryLength: inputQuery.length,
		queryUpdatedAgo: queryUpdatedTime ? Date.now() - queryUpdatedTime : undefined,
		queryVersion,
		searchSessionId,
		wordCount: (inputQuery.match(/\w+/g) || []).length,
		isQuickFind: true,
		mode: applicationMode,
	};

	const nonPrivacySafeAttributes = {
		query: inputQuery,
	};

	return { commonAttributes, nonPrivacySafeAttributes };
};
