import { type CompassURLGenerators } from './types';

const getBaseURL = () => window.location.href.replace(/\/$/, '');

/**
 * Generates URL functions for the Compass tab, with appropriate absolute URLs.
 * @param {string} searchUrlOverride - Override for the base URL used for search links
 * @returns functions for generating Compass URLs
 */
export const compassURLGenerators = (searchUrlOverride?: string): CompassURLGenerators => {
	const advancedSearchLinkGenerator = (query: string) =>
		`${searchUrlOverride || getBaseURL()}/compass/components?query=${encodeURIComponent(query)}`;

	const teamUrlGenerator = (teamId: string) => `${getBaseURL()}/compass/people/team/${teamId}`;

	const userUrlGenerator = (userId: string) => `${getBaseURL()}/people/${userId}`;

	return {
		advancedSearchLinkGenerator,
		teamUrlGenerator,
		userUrlGenerator,
	};
};
