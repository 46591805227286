export const State = {
	PREQUERY_ERROR: 'PREQUERY_ERROR',
	PREQUERY_LOADING: 'PREQUERY_LOADING',
	PREQUERY_NO_RESULTS: 'PREQUERY_NO_RESULTS',
	PREQUERY_RESULTS: 'PREQUERY_RESULTS',
	POSTQUERY_ERROR: 'POSTQUERY_ERROR',
	POSTQUERY_LOADING: 'POSTQUERY_LOADING',
	POSTQUERY_NO_RESULTS: 'POSTQUERY_NO_RESULTS',
	POSTQUERY_RESULTS: 'POSTQUERY_RESULTS',
} as const;

export type State = (typeof State)[keyof typeof State];

export const isPreQueryState = (state: State) => {
	return (
		state === State.PREQUERY_ERROR ||
		state === State.PREQUERY_LOADING ||
		state === State.PREQUERY_NO_RESULTS ||
		state === State.PREQUERY_RESULTS
	);
};

export const isPostQueryState = (state: State) => {
	return (
		state === State.POSTQUERY_ERROR ||
		state === State.POSTQUERY_LOADING ||
		state === State.POSTQUERY_NO_RESULTS ||
		state === State.POSTQUERY_RESULTS
	);
};
