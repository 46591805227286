import { useCallback } from 'react';

import { addRecentSearch, RECENT_SEARCH_KEY } from '@atlassian/search-common';

import { type SearchResultEventArguments } from '../../common/ui/quick-find/search-dialog-result';
import { onAdvancedSearchLinkSelected } from '../../common/utils/quick-find/events/advanced-search-link-selected';
import { onSearchDialogResultContextMenuOpened } from '../../common/utils/quick-find/events/search-dialog-result-context-menu-opened';
import { onSearchDialogResultHighlighted } from '../../common/utils/quick-find/events/search-dialog-result-highlighted';
import { onSearchDialogResultSelected } from '../../common/utils/quick-find/events/search-dialog-result-selected';
import { State } from '../../common/utils/quick-find/state';
import { useInputQuery, usePrimaryProduct, useUser } from '../../controllers/store';
import { useQuickFindActions, useUpdateRecentQueries } from '../../controllers/store/quick-find';

import { useQuickFindAnalytics, useQuickFindAttributes } from './analytics';

export function getActionSubjectId(state: State) {
	switch (state) {
		case State.PREQUERY_RESULTS:
			return 'preQuerySearchResults';
		case State.PREQUERY_LOADING:
			return 'preQueryLoadingSearchResults';
		case State.POSTQUERY_LOADING:
			return 'postQueryLoadingSearchResults';
		case State.POSTQUERY_RESULTS:
			return 'postQuerySearchResults';
		default:
			return '';
	}
}

type onClickOptions = {
	addCurrentQueryAsRecentQuery?: boolean;
};

export type QuickFindResultEvent = {
	event?: React.MouseEvent<HTMLDivElement>;
	data: SearchResultEventArguments;
	state: State;
	additionalAnalyticsAttributes?: Record<string, any>;
	options?: onClickOptions;
};

export const useQuickFindResultOnClick = () => {
	const [inputQuery] = useInputQuery();
	const [user] = useUser();
	const [primaryProduct] = usePrimaryProduct();
	const updateRecentQueries = useUpdateRecentQueries();

	const { setQuickFindOpen } = useQuickFindActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	return useCallback(
		(props: QuickFindResultEvent) => {
			const { event, data, state, additionalAnalyticsAttributes, options } = props;

			const { addCurrentQueryAsRecentQuery = true } = options || {};

			if (event && !event.ctrlKey && !event.metaKey) {
				setQuickFindOpen(false);
			}

			if (addCurrentQueryAsRecentQuery) {
				const trimmedInuptQuery = inputQuery.trim();
				if (trimmedInuptQuery !== '' && user && user.id && primaryProduct) {
					addRecentSearch(trimmedInuptQuery, user.id, primaryProduct, RECENT_SEARCH_KEY);
					updateRecentQueries?.();
				}
			}

			const actionSubjectId = getActionSubjectId(state);

			fireAnalyticsEvent(
				onSearchDialogResultSelected({
					actionSubjectId,
					attributes: {
						...commonAttributes,
						...additionalAnalyticsAttributes,
						...data,
					},
					nonPrivacySafeAttributes,
				}),
			);
		},
		[
			commonAttributes,
			fireAnalyticsEvent,
			inputQuery,
			nonPrivacySafeAttributes,
			primaryProduct,
			setQuickFindOpen,
			updateRecentQueries,
			user,
		],
	);
};

export const useQuickFindResultOnContextMenu = () => {
	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	return useCallback(
		(props: QuickFindResultEvent) => {
			const { data, state, additionalAnalyticsAttributes } = props;

			const actionSubjectId = getActionSubjectId(state);

			fireAnalyticsEvent(
				onSearchDialogResultContextMenuOpened({
					actionSubjectId,
					attributes: {
						...commonAttributes,
						...additionalAnalyticsAttributes,
						...data,
					},
					nonPrivacySafeAttributes,
				}),
			);
		},
		[commonAttributes, fireAnalyticsEvent, nonPrivacySafeAttributes],
	);
};

export const useQuickFindResultOnHighlighted = () => {
	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	return useCallback(
		(props: QuickFindResultEvent) => {
			const { data, state, additionalAnalyticsAttributes } = props;

			const actionSubjectId = getActionSubjectId(state);

			fireAnalyticsEvent(
				onSearchDialogResultHighlighted({
					actionSubjectId,
					attributes: {
						...commonAttributes,
						...additionalAnalyticsAttributes,
						...data,
					},
					nonPrivacySafeAttributes,
				}),
			);
		},
		[commonAttributes, fireAnalyticsEvent, nonPrivacySafeAttributes],
	);
};

export type QuickFindFooterEvent = {
	event: React.MouseEvent<HTMLDivElement>;
	state: State;
	options?: onClickOptions;
};

export const useQuickFindFooterOnClick = () => {
	const [inputQuery] = useInputQuery();
	const [user] = useUser();
	const [primaryProduct] = usePrimaryProduct();
	const updateRecentQueries = useUpdateRecentQueries();

	const { setQuickFindOpen } = useQuickFindActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	return useCallback(
		(props: QuickFindFooterEvent) => {
			const { event, state, options } = props;
			const { addCurrentQueryAsRecentQuery = true } = options || {};

			const newTab = event.ctrlKey || event.metaKey || event.shiftKey;

			if (!newTab) {
				setQuickFindOpen(false);
			}

			if (addCurrentQueryAsRecentQuery) {
				const trimmedInuptQuery = inputQuery.trim();
				if (trimmedInuptQuery !== '' && user && user.id && primaryProduct) {
					addRecentSearch(trimmedInuptQuery, user.id, primaryProduct, RECENT_SEARCH_KEY);
					updateRecentQueries?.();
				}
			}

			if (!primaryProduct) {
				return;
			}

			fireAnalyticsEvent(
				onAdvancedSearchLinkSelected({
					primaryProduct,
					attributes: {
						...commonAttributes,
						trigger: 'click',
						newTab,
						isLoading: state === State.PREQUERY_LOADING || state === State.POSTQUERY_LOADING,
					},
					nonPrivacySafeAttributes,
				}),
			);
		},
		[
			commonAttributes,
			fireAnalyticsEvent,
			inputQuery,
			nonPrivacySafeAttributes,
			primaryProduct,
			setQuickFindOpen,
			updateRecentQueries,
			user,
		],
	);
};
