import { useEffect, useRef } from 'react';

/**
 * Syncs two states together by calling the respective setState of the state that
 * requires an update.
 *
 * NOTE: The initial values of each state can only be undefined once. Any subsequent
 * calls to setState(undefined) will reset back to its previous defined value. Do not
 * use this if undefined is a valid value for your states.
 */
export function useSyncStates<T>(
	stateA?: T,
	setStateA?: (s: T) => void,
	stateB?: T,
	setStateB?: (s: T) => void,
) {
	const prevA = useRef(stateA);
	const prevB = useRef(stateB);

	// Sync the two states on initial mount
	useEffect(() => {
		if (stateA === stateB) {
			return;
		}

		// stateA takes precedence if both states have a value
		if (stateA) {
			setStateB?.(stateA);
			return;
		}
		if (stateB) {
			setStateA?.(stateB);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (stateA === undefined || stateB === undefined || stateA === stateB) {
			return;
		}

		if (prevA.current !== stateA) {
			prevA.current = stateA;
			setStateB?.(stateA);
			return; // Exit here as it should only run one setState at a time
		}

		if (prevB.current !== stateB) {
			prevB.current = stateB;
			setStateA?.(stateB);
		}
	}, [setStateA, setStateB, stateA, stateB]);
}
