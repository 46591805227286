import React, { useCallback } from 'react';

import Avatar from '@atlaskit/avatar';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import {
	isSearchConfluencePageBlogAttachment,
	isSearchConfluencePartial,
	type SearchPageNode,
} from '@atlassian/search-client';
import {
	getConfluenceUrlWithAnalyticsQueryParams,
	highlightMatchedText,
} from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { ProductKeys } from '../../../../common/constants/products';
import { EventTypeMessage, WORKED_EVENT } from '../../../../common/ui/event-type-message';
import { ProductIcon } from '../../../../common/ui/product-icon';
import { ContentIcon } from '../../../../common/ui/quick-find/content-icon';
import {
	SearchResult,
	type SearchResultEventArguments,
} from '../../../../common/ui/quick-find/search-dialog-result';
import { type SearchMetadataPart } from '../../../../common/ui/search-metadata';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindResultEvent } from '../../../../controllers/quick-find/utils';
import { useInputQuery, useSearchSessionId } from '../../../../controllers/store';

const typeLabelContainer = xcss({
	'::first-letter': {
		textTransform: 'capitalize',
	},
});

const mapSearchResult = (result: SearchPageNode, query: string, searchSessionId: string) => {
	if (!isSearchConfluencePartial(result)) {
		return null;
	}

	const id = result.id;

	const icon = fg('rovo_search_fy25q2_visual_refresh') ? (
		<ProductIcon size="small" product={ProductKeys.Confluence} />
	) : (
		<ContentIcon
			type={result.type}
			title={result.title}
			fallbackIcon={
				result.iconUrl && (
					<Avatar
						size="small"
						borderColor="transparent"
						src={result.iconUrl}
						appearance="square"
						testId="result-avatar"
					/>
				)
			}
		/>
	);

	const title = result.title;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const space =
		isSearchConfluencePageBlogAttachment(result) && result.space?.name ? result.space.name : null;
	const spaceId = isSearchConfluencePageBlogAttachment(result) && result.space?.id;

	const metadata = [
		'Confluence',
		['type', <Box xcss={typeLabelContainer}>{result.type}</Box>],
		space,
	] satisfies SearchMetadataPart[];

	const timestamp = result.lastModified || '';
	const eventType = WORKED_EVENT.UPDATED;
	const rightMetadata = result.lastModified ? (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} />
	) : null;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const url = getConfluenceUrlWithAnalyticsQueryParams({ url: result.url, query, searchSessionId });

	const analyticsMetadata = {
		contentId: id,
		containerId: spaceId,
		contentType: `confluence ${result.type}`,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

type Props = {
	result: SearchPageNode;
	state: State;
	onClick?: (props: QuickFindResultEvent) => void;
	onContextMenu?: (props: QuickFindResultEvent) => void;
	onHighlighted?: (props: QuickFindResultEvent) => void;
};

export const ProductEntity = ({ result, state, onClick, onContextMenu, onHighlighted }: Props) => {
	const [inputQuery] = useInputQuery();
	const [searchSessionId] = useSearchSessionId();

	const mappedResult = mapSearchResult(result, inputQuery, searchSessionId);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onClick?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onClick, state],
	);

	const onResultContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onContextMenu?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onContextMenu, state],
	);

	const onResultHighlighted = useCallback(
		(data: SearchResultEventArguments) => {
			onHighlighted?.({
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onHighlighted, state],
	);

	if (!mappedResult) {
		return null;
	}

	return (
		<SearchResult
			{...mappedResult}
			onResultClicked={onResultClicked}
			onResultContextMenu={onResultContextMenu}
			onResultHighlighted={onResultHighlighted}
		/>
	);
};
