import React, { type ReactNode, Suspense } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Box, Pressable, xcss } from '@atlaskit/primitives';

import { useAppContext } from '../../../../common/ui/app-context';
import { useInputQuery } from '../../../../controllers/store';

import { messages } from './messages';

const Image = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/search-error-image" */ './image'
		),
);

const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 'space.200',
	color: 'color.text',
});

const imageFallbackStyles = xcss({
	width: '124px',
	height: '124px',
});

const headingStyles = xcss({
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	marginTop: 'space.100',
});

const bodyStyles = xcss({
	marginTop: 'space.100',
	fontSize: 'font.body',
});

const pressableStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	fontSize: 'font.body',
	color: 'color.link',
	padding: 'space.0',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
});

type ErrorScreenProps = {
	onRetry: () => void;
};

export const NoResultsScreen = ({ onRetry }: ErrorScreenProps) => {
	const { linkComponent: Link, generateSearchUrl, queryParams } = useAppContext();
	const [inputQuery] = useInputQuery();

	return (
		<Box xcss={containerStyles}>
			<Suspense fallback={<Box xcss={imageFallbackStyles} />}>
				<Image />
			</Suspense>
			<Box xcss={headingStyles}>
				<FormattedMessage
					{...messages.noResultsHeading}
					values={{
						button: (s: ReactNode[]) => (
							<Pressable xcss={pressableStyles} onClick={onRetry}>
								{s}
							</Pressable>
						),
					}}
				/>
			</Box>
			<Box xcss={bodyStyles}>
				<FormattedMessage
					{...messages.noResultsBody}
					values={{
						link: (s: ReactNode[]) => (
							<Link
								xcss={pressableStyles}
								href={generateSearchUrl({
									...queryParams,
									text: inputQuery,
								})}
							>
								{s}
							</Link>
						),
					}}
				/>
			</Box>
		</Box>
	);
};
