import React, { type ReactNode, useCallback, useRef, useState } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { enrichResultTitle } from '@atlassian/search-common';
import { useKeyboardNavigation } from '@atlassian/search-dialog';

import {
	getNodePosition,
	SearchDialogResultAnalyticsWrapper,
} from '../../../utils/quick-find/analytics';
import { useAppContext } from '../../app-context';
import { SearchMetadata, type SearchMetadataPart } from '../../search-metadata';

const containerStyles = xcss({
	marginInlineStart: 'space.100',
	marginInlineEnd: 'space.100',
});

const resultStyles = xcss({
	paddingBlockStart: 'space.075',
	paddingBlockEnd: 'space.075',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.100',
	borderRadius: 'border.radius.200',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
});

const resultHoveredStlyes = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const keyboardHighlightedStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const iconAreaStyles = xcss({
	width: 'size.200',
	height: 'size.200',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const middleAreaStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '0',
	flex: 1,
	marginLeft: 'space.150',
});

const titleStyles = xcss({
	overflow: 'clip',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	font: 'font.body',
	lineHeight: '16px',
});

const metadataStyles = xcss({
	color: 'color.text.subtlest',
	marginTop: 'space.050',
	font: 'font.body.small',
	lineHeight: '13px',
	display: 'flex',
	alignItems: 'center',
	minWidth: '0',
});

const textOverflowStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const rightSectionStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	marginLeft: 'space.150',
});

const linkStyles = xcss({
	color: 'color.text',
	textDecoration: 'none',
	display: 'inline-block',
	width: '100%',
	borderRadius: 'border.radius.200',

	':hover': {
		textDecoration: 'none',
		color: 'color.text',
	},

	':visited': {
		textDecoration: 'none',
		color: 'color.text',
	},
});

type SearchResultProps = {
	id?: string;
	icon: ReactNode;
	metadata?: SearchMetadataPart[];
	title: ReactNode;
	url?: string;
	rightMetadata?: ReactNode;
	highlightedRightMetadata?: ReactNode;
	hoveredRightMetadata?: ReactNode;
	isThirdParty?: boolean;
	cloudId?: string;
	highlightedTitle?: string;
	includeInAnalytics?: boolean;

	onResultClicked?: (e: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => void;
	onResultContextMenu?: (
		e: React.MouseEvent<HTMLDivElement>,
		data: SearchResultEventArguments,
	) => void;
	onResultHighlighted?: (data: SearchResultEventArguments) => void;
};

export type SearchResultEventArguments = {
	sectionCount?: number;
	sectionIndex?: number;
	resultCount?: number;
	globalIndex?: number;
	indexWithinSection?: number;
};

export const SearchResult = ({
	icon,
	title,
	metadata = [],
	rightMetadata,
	hoveredRightMetadata,
	highlightedRightMetadata,
	url,
	isThirdParty = false,
	highlightedTitle,
	includeInAnalytics = true,
	onResultClicked,
	onResultContextMenu,
	onResultHighlighted,
}: SearchResultProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const ref = useRef<HTMLDivElement>();

	const [isHovered, setIsHovered] = useState(false);

	const { linkComponent: Link } = useAppContext();

	const [isKeyboardHighlighted, setRef] = useKeyboardNavigation<HTMLDivElement>({
		onKeydownCallback: (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				ref.current?.click();
			}
		},
		onHighlightedCallback: () => {
			const data = wrapperRef.current ? getNodePosition(wrapperRef.current) : {};
			onResultHighlighted?.(data);
		},
	});

	const onClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const data = wrapperRef.current ? getNodePosition(wrapperRef.current) : {};
			onResultClicked?.(event, data);
		},
		[onResultClicked],
	);

	const onContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const data = wrapperRef.current ? getNodePosition(wrapperRef.current) : {};
			onResultContextMenu?.(event, data);
		},
		[onResultContextMenu],
	);

	const content = (
		<Box
			ref={(node: HTMLDivElement) => {
				ref.current = node;
				setRef(node);
			}}
			xcss={[
				resultStyles,
				isHovered && resultHoveredStlyes,
				isKeyboardHighlighted && keyboardHighlightedStyles,
			]}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Box xcss={iconAreaStyles}>{icon}</Box>
			<Box xcss={middleAreaStyles}>
				<Box xcss={[titleStyles, textOverflowStyles]}>
					{highlightedTitle ? enrichResultTitle(highlightedTitle) : title}
				</Box>
				{metadata.length >= 1 && (
					<Box xcss={[metadataStyles]}>
						<SearchMetadata parts={metadata} />
					</Box>
				)}
			</Box>
			{hoveredRightMetadata && isHovered ? (
				<Box xcss={rightSectionStyles}>
					<Show above="xs">{hoveredRightMetadata}</Show>
				</Box>
			) : highlightedRightMetadata && isKeyboardHighlighted ? (
				<Box xcss={rightSectionStyles}>
					<Show above="xs">{highlightedRightMetadata}</Show>
				</Box>
			) : rightMetadata ? (
				<Box xcss={rightSectionStyles}>
					<Show above="xs">{rightMetadata}</Show>
				</Box>
			) : null}
		</Box>
	);

	const resultWithoutAnalyticsWrapper = (
		<Box xcss={containerStyles}>
			<Box onClick={onClick} onContextMenu={onContextMenu}>
				{url ? (
					<Link
						xcss={[linkStyles]}
						rel={isThirdParty ? 'noreferrer' : ''}
						target={isThirdParty ? '_blank' : undefined}
						href={url}
					>
						{content}
					</Link>
				) : (
					<>{content}</>
				)}
			</Box>
		</Box>
	);

	return includeInAnalytics ? (
		<SearchDialogResultAnalyticsWrapper
			role="listitem"
			data-testid="search-dialog-result"
			ref={wrapperRef}
		>
			{resultWithoutAnalyticsWrapper}
		</SearchDialogResultAnalyticsWrapper>
	) : (
		resultWithoutAnalyticsWrapper
	);
};
