import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CancellablePromise } from '@atlassian/search-client';

import { filterSearchResults } from '../../../common/utils/filter-search-results';
import { measureAsyncFunction } from '../../../common/utils/measure-duration';
import { useDebouncedCallback } from '../../../common/utils/use-debounced-callback';
import { useLogException } from '../../error-boundary';
import { useSelectedFilters } from '../../filters/selected';
import {
	useBackendExperiment,
	useBootstrap,
	useInputQuery,
	useIsSearchReady,
	useRetryCount,
	useSearchSessionId,
} from '../../store';
import { useQuickFindActions, useQuickFindOpen } from '../../store/quick-find';

import { fetchRecentActivities } from './activities';
import { useQuickFindResultsCallbacks } from './callbacks';
import { fetchPeopleAndTeamsResults } from './people-and-teams';
import { fetchConfluenceEntities, fetchConfluenceSpaces } from './product-entities';
import { getRecentQueries } from './recent-queries';
import {
	getQuickFindContent,
	PeopleAndTeams,
	RecentActivities,
	RecentQueries,
	SearchResults,
} from './types';

const DEBOUNCE_TIMEOUT = 200;

const EMPTY_RESULTS = {
	result: [],
	duration: 0,
};

type ResultCallbacks = {
	onPreQueryStart?: () => void;
	onPreQuerySuccess?: () => void;
	onPreQueryFailure?: () => void;
	onPostQueryStart?: () => void;
	onPostQuerySuccess?: (duration: number) => void;
	onPostQueryFailure?: () => void;
	onPostQueryCancel?: () => void;
};

/**
 * Fetches search results using search parameters from the query
 * and filter stores. During and after requests, the results store is
 * updated accordingly. If multiple requests are fired only the last
 * request will cause the results store to be updated.
 */
export const useQuickFindResults = ({
	onPreQueryStart,
	onPreQuerySuccess,
	onPreQueryFailure,
	onPostQueryStart,
	onPostQuerySuccess,
	onPostQueryFailure,
	onPostQueryCancel,
}: ResultCallbacks) => {
	const prevInputQueryRef = useRef<string>();
	const postQueryPromiseRef = useRef<CancellablePromise<any>>();

	const [bootstrap] = useBootstrap();
	const isSearchReady = useIsSearchReady();
	const [searchSessionId] = useSearchSessionId();
	const { selectedFilters, entities } = useSelectedFilters({
		outboundAuthConfigs: bootstrap.outboundAuthConfigs,
		cloudId: bootstrap.cloudId,
	});
	const [inputQuery] = useInputQuery();
	const [backendExperiment] = useBackendExperiment();
	const { experimentId, shadowExperimentId, experimentLayers } = backendExperiment || {};
	const logException = useLogException();
	const [retryCount] = useRetryCount();
	const quickFindOpen = useQuickFindOpen();

	const { setUpdateRecentQueries } = useQuickFindActions();

	const [hasQuery, setHasQuery] = useState(false);

	const [preQueryLoading, setPreQueryLoading] = useState(false);
	const [preQueryError, setPreQueryError] = useState<Error | undefined>();

	const [postQueryLoading, setPostQueryLoading] = useState(false);
	const [postQueryError, setPostQueryError] = useState<Error | undefined>();

	const [recentQueries, setRecentQueries] = useState<RecentQueries>([]);
	const [filteredRecentQueries, setFilteredRecentQueries] = useState<RecentQueries>([]);
	const [recentActivities, setRecentActivities] = useState<RecentActivities>(EMPTY_RESULTS);
	const [filteredRecentActivities, setFilteredRecentActivities] =
		useState<RecentActivities>(EMPTY_RESULTS);
	const [searchResults, setSearchResults] = useState<SearchResults>(EMPTY_RESULTS);
	const [peopleAndTeams, setPeopleAndTeams] = useState<PeopleAndTeams>(EMPTY_RESULTS);

	/**
	 * We compare these via deep value equality, as currently the objects are regenerated.
	 * By comparing deep value equality, we avoid searching unnecessarily.
	 *
	 * TODO: Refactor this so we can go back to Object.is dependency equality on these objects
	 */
	const selectedFiltersValueEquality = JSON.stringify(selectedFilters);
	const entitiesValueEquality = JSON.stringify(entities);
	const experimentLayersValueEquality = JSON.stringify(experimentLayers);

	/**
	 * Callback to update recent queries for the current product and user
	 */
	const fetchRecentQueries = useCallback(() => {
		if (!bootstrap.primaryProduct || !bootstrap.user?.id) {
			return;
		}

		const recentQueries = getRecentQueries(bootstrap.primaryProduct, bootstrap.user.id);
		setRecentQueries(recentQueries);
	}, [bootstrap.primaryProduct, bootstrap.user?.id, setRecentQueries]);

	/**
	 * Store the callback to update recent queries in the store. This is used to refresh recent queries
	 * when they are updated, e.g. when a search request is made or when a recent query is removed.
	 */
	useEffect(() => {
		fetchRecentQueries();
		setUpdateRecentQueries(fetchRecentQueries);
	}, [fetchRecentQueries, setUpdateRecentQueries]);

	/**
	 * Callback to fetch recent activities
	 */
	const fetchRecentActivitiesCallback = useCallback(async () => {
		if (bootstrap.cloudId) {
			return fetchRecentActivities(bootstrap.cloudId, bootstrap.aggAbsoluteUrl);
		} else {
			return [];
		}
	}, [bootstrap.cloudId, bootstrap.aggAbsoluteUrl]);

	/**
	 * Callback to fetch pre-query results and updates the store with the results
	 */
	const fetchQuickFindPreQuery = useCallback(async () => {
		setPreQueryLoading(true);
		onPreQueryStart?.();

		await measureAsyncFunction(fetchRecentActivitiesCallback)
			.then((response) => {
				setRecentActivities(response);
				onPreQuerySuccess?.();
			})
			.catch((e) => {
				logException(e);
				setPreQueryError(e);
				onPreQueryFailure?.();
			});

		setPreQueryLoading(false);
	}, [
		fetchRecentActivitiesCallback,
		logException,
		onPreQueryFailure,
		onPreQueryStart,
		onPreQuerySuccess,
		setPreQueryLoading,
		setRecentActivities,
	]);

	/**
	 * Given a query, sets the filtered recent queries and recent activities
	 */
	const fetchFilteredPreQueryResults = useCallback(
		(query) => {
			const filteredRecentActivites = filterSearchResults(recentActivities.result, query);
			const filteredRecentQueries = filterSearchResults(recentQueries, query);

			setFilteredRecentQueries(filteredRecentQueries);
			setFilteredRecentActivities({
				result: filteredRecentActivites,
				duration: recentActivities.duration,
			});
		},
		[recentActivities, recentQueries],
	);

	/**
	 * Fetches pre-query results, should only happen a single time when the search page is ready
	 */
	useEffect(() => {
		if (!isSearchReady) {
			return;
		}

		fetchQuickFindPreQuery();
	}, [fetchQuickFindPreQuery, isSearchReady]);

	/**
	 * Callback to fetch confluence entities
	 */
	const fetchConfluenceEntitiesCallback = useCallback(
		async () => {
			return Promise.all([
				fetchConfluenceEntities(
					bootstrap.aggAbsoluteUrl,
					inputQuery,
					bootstrap.primaryProduct,
					bootstrap.cloudId || '',
					searchSessionId,
					experimentId,
					shadowExperimentId,
					experimentLayers?.map(({ name, layerId, shadowId }) => ({
						name,
						layerId,
						shadowId,
					})),
				),
				fetchConfluenceSpaces(
					bootstrap.aggAbsoluteUrl,
					inputQuery,
					bootstrap.primaryProduct,
					bootstrap.cloudId || '',
					searchSessionId,
					experimentId,
					shadowExperimentId,
					experimentLayers?.map(({ name, layerId, shadowId }) => ({
						name,
						layerId,
						shadowId,
					})),
				),
			]).then((results) => {
				const [entities, spaces] = results;
				return [...entities, ...spaces];
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			bootstrap.aggAbsoluteUrl,
			bootstrap.cloudId,
			bootstrap.primaryProduct,
			experimentId,
			experimentLayersValueEquality, // experimentLayers,
			inputQuery,
			searchSessionId,
			shadowExperimentId,
		],
	);

	/**
	 * Callback to fetch people and teams results
	 */
	const fetchPeopleAndTeamsCallback = useCallback(async () => {
		const { aggAbsoluteUrl, orgId, primaryProduct, user, cloudId } = bootstrap;
		if (orgId && primaryProduct && user?.id && cloudId) {
			return fetchPeopleAndTeamsResults(
				aggAbsoluteUrl,
				inputQuery,
				orgId,
				primaryProduct,
				user.id,
				cloudId,
			);
		} else {
			return [];
		}
	}, [bootstrap, inputQuery]);

	/**
	 * Function which fetches the post query results. This consists of a call to get
	 * product search results and a call to get people and teams. Given that Promise.allSettled
	 * is used, the slowest request will determine the time it takes to resolve.
	 *
	 * This isn't ideal, however I'm choosing not to invest time into making this better
	 * as Kier has a better solution in the works.
	 */
	const fetchQuickFindPostQuery = useCallback(() => {
		// Cancel the inflight request if there is one
		if (postQueryPromiseRef.current) {
			postQueryPromiseRef.current.cancel();
		}

		const fetchProductEntities =
			bootstrap.primaryProduct === 'confluence'
				? fetchConfluenceEntitiesCallback
				: () => Promise.resolve([]);

		const fetchProductEntitiesPromise = new CancellablePromise(
			Promise.allSettled([
				measureAsyncFunction(fetchProductEntities),
				measureAsyncFunction(fetchPeopleAndTeamsCallback),
			]),
		);
		postQueryPromiseRef.current = fetchProductEntitiesPromise;

		onPostQueryStart?.();

		fetchProductEntitiesPromise
			.promise()
			.then((results) => {
				const productEntitiesStatus = results[0].status;
				const peopleAndTeamsStatus = results[1].status;

				if (productEntitiesStatus === 'fulfilled' && peopleAndTeamsStatus === 'fulfilled') {
					setSearchResults({
						result: results[0].value.result,
						duration: results[0].value.duration,
					});
					setPeopleAndTeams({
						result: results[1].value.result,
						duration: results[1].value.duration,
					});

					const allDurations = results.map((result) =>
						result.status === 'fulfilled' ? result.value.duration : 0,
					);
					const maxDuration = Math.max(...allDurations);

					onPostQuerySuccess?.(maxDuration);
				} else {
					setPostQueryError(new Error('Failed to fetch post query results'));
					onPostQueryFailure?.();
				}

				setPostQueryLoading(false);
			})
			.catch(() => {
				// Promise.allSettled will never reject, hence rejections only occur
				// when the promise is cancelled. In this case, we don't need to do anything.
			});
	}, [
		bootstrap.primaryProduct,
		fetchConfluenceEntitiesCallback,
		fetchPeopleAndTeamsCallback,
		onPostQueryFailure,
		onPostQueryStart,
		onPostQuerySuccess,
		setPeopleAndTeams,
		setPostQueryError,
		setPostQueryLoading,
		setSearchResults,
	]);

	/**
	 * Debounced fetch function for quick find results. This callback should never mutate
	 * as the only dependency is the timeout value, hence it can be safely used in dependency array.
	 */
	const debouncedFetchQuickFindPostQuery = useDebouncedCallback(
		fetchQuickFindPostQuery,
		DEBOUNCE_TIMEOUT,
	);

	/**
	 * Updates results when various Quick Find states change, this includes:
	 * - when search is ready
	 * - when the dialog opens
	 * - when the query changes
	 * - when selected entities or filters change
	 */
	useEffect(() => {
		if (!isSearchReady || !quickFindOpen) {
			return;
		}

		fetchFilteredPreQueryResults(inputQuery);

		if (inputQuery === prevInputQueryRef.current) {
			return;
		}

		if (inputQuery === '') {
			onPostQueryCancel?.();
		}

		prevInputQueryRef.current = inputQuery;

		setHasQuery(Boolean(inputQuery));

		setSearchResults({ result: [], duration: 0 });
		setPeopleAndTeams({ result: [], duration: 0 });
		setPostQueryLoading(true);
		setPostQueryError(undefined);

		if (inputQuery) {
			setPostQueryLoading(true);
			debouncedFetchQuickFindPostQuery();
		}
	}, [
		debouncedFetchQuickFindPostQuery,
		inputQuery,
		retryCount,
		isSearchReady,
		quickFindOpen,
		selectedFiltersValueEquality,
		entitiesValueEquality,
		fetchFilteredPreQueryResults,
		onPostQueryCancel,
	]);

	return useMemo(
		() =>
			getQuickFindContent({
				hasQuery,
				preQueryError,
				preQueryLoading,
				postQueryError,
				postQueryLoading,
				filteredRecentQueries,
				filteredRecentActivities,
				searchResults,
				peopleAndTeams,
			}),
		[
			filteredRecentActivities,
			filteredRecentQueries,
			hasQuery,
			peopleAndTeams,
			postQueryError,
			postQueryLoading,
			preQueryError,
			preQueryLoading,
			searchResults,
		],
	);
};

export const useQuickFindResultsWithAnalytics = () => {
	const callbacks = useQuickFindResultsCallbacks();
	return useQuickFindResults(callbacks);
};
