import React, { useCallback, useMemo, useState } from 'react';

import { QuickFind as QuickFindInternal } from '@atlassian/search-page/quick-find';

import { TOUCH } from '@confluence/navdex';
import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';
import { createSingleQueryParamHook, useRouteActions } from '@confluence/route-manager';
import {
	GeneralShortcutListener,
	NAVIGATION_SHORTCUT,
	NAVIGATION_SLASH_SHORTCUT,
	NAVIGATION_SLASH_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

const mapUserIdToProfileUrl = (userId: string) => `/wiki/people/${userId}`;

const useTextParameter = createSingleQueryParamHook('text');

export const QuickFind = () => {
	const [open, setOpen] = useState(false);

	const { cloudId, orgId, activationId, isAdminHubAIEnabled, userId, displayName } =
		useSessionData();
	const { push } = useRouteActions();

	const [{ isEditMode }] = useLivePageMode();

	const textParameter = useTextParameter();
	const queryParams = useMemo(
		() => ({
			text: textParameter,
		}),
		[textParameter],
	);

	const analyticsAttributes = useMemo(
		() => ({ activationId, navdexPointType: TOUCH }),
		[activationId],
	);

	const generateSearchUrl = useCallback((urlQueryParams: any) => {
		const url = new URL('/wiki/search', window.location.origin);
		const searchParams = new URLSearchParams(window.location.search);
		Object.entries(urlQueryParams).forEach(([key, value]) => {
			if (value) {
				searchParams.set(key, String(value));
			}
		});
		url.search = searchParams.toString();
		return url.href;
	}, []);

	const user = useMemo(() => ({ id: userId, name: displayName }), [userId, displayName]);

	return (
		<>
			<QuickFindInternal
				cloudId={cloudId}
				orgId={orgId}
				primaryProduct="confluence"
				analyticsAttributes={analyticsAttributes}
				isAdminHubAIEnabled={isAdminHubAIEnabled}
				isNav4Enabled={fg('confluence_nav_4')}
				mapUserIdToProfileUrl={mapUserIdToProfileUrl}
				onNavigate={(url) => push(url)}
				queryParams={queryParams}
				open={open}
				setOpen={setOpen}
				user={user}
				generateSearchUrl={generateSearchUrl}
			/>
			<GeneralShortcutListener
				accelerator={
					isEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')
						? NAVIGATION_SLASH_SHORTCUT_IN_EDIT
						: [NAVIGATION_SHORTCUT, NAVIGATION_SLASH_SHORTCUT]
				}
				listener={() => setOpen(true)}
				isAppNavigationShortcut
			/>
		</>
	);
};
