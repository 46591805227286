import React, { type RefObject, useEffect, useMemo, useRef } from 'react';

import { useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { isSearchConfluenceSpace } from '@atlassian/search-client';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { PrimaryProductKeys } from '../../../common/constants/products';
import { Heading } from '../../../common/ui/quick-find/heading';
import { getSectionsAndResultsCount } from '../../../common/utils/quick-find/analytics';
import { onSearchDialogDismissed } from '../../../common/utils/quick-find/events/search-dialog-dismissed';
import { onSearchDialogShown } from '../../../common/utils/quick-find/events/search-dialog-shown';
import { onSearchResultsShown } from '../../../common/utils/quick-find/events/search-results-shown';
import { isPostQueryState, State } from '../../../common/utils/quick-find/state';
import ShowIfEligible from '../../../controllers/application-modes';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import { QuickFindContent } from '../../../controllers/quick-find/fetch-results/types';
import {
	useQuickFindFooterOnClick,
	useQuickFindResultOnClick,
	useQuickFindResultOnContextMenu,
	useQuickFindResultOnHighlighted,
} from '../../../controllers/quick-find/utils';
import { usePrimaryProduct, useSearchActions } from '../../../controllers/store';
import { useBootstrapActions } from '../../../controllers/store/bootstrap';

import { RovoTipBanner } from './banners/rovo';
import { Divider } from './divider';
import { ErrorScreen } from './error-screen';
import { FeedbackFooter } from './feedback-footer';
import { LandingScreen } from './landing-screen';
import { messages } from './messages';
import { NoResultsScreen } from './no-results-screen';
import { PeopleAndTeams } from './people-and-teams';
import { ProductEntity } from './product-entitiy';
import { RecentActivity } from './recent-activity';
import { RecentQuery } from './recent-query';
import { SearchResultSkeleton } from './result-skeleton';
import { SearchPageLink } from './search-page-link';
import { SearchPagePrimaryProductLink } from './search-page-primary-product-link';
import {
	isErrorItem,
	isHeadingItem,
	isLandingItem,
	isNoResultsItem,
	isPeopleAndTeamsItem,
	isRecentActivityItem,
	isRecentQueryItem,
	isSearchResultItem,
	isSkeletonItem,
	type Item,
} from './types';

const searchDialogStyles = xcss({
	display: 'flex',
	position: 'absolute',
	flexDirection: 'column',
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.overlay',
	maxHeight: 'calc(100vh - 60px)',
	overflow: 'scroll',
	marginTop: 'space.100',
	width: '100%',
});

const searchResultsSectionStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.100',
	gap: 'space.025',
});

type SearchDialogProps = {
	dialogId: string;
	dialogRef: RefObject<HTMLDivElement>;
	content: QuickFindContent;
};

export const SearchDialog = ({
	dialogId,
	dialogRef,
	content,
	content: { state },
}: SearchDialogProps) => {
	const intl = useIntl();

	const [primaryProduct] = usePrimaryProduct();
	const { retrySearch } = useSearchActions();

	const allItems = useContent(content);

	useResetSessionAnalytics();
	useAnalyticsEvents({ state });

	const resultOnClick = useQuickFindResultOnClick();
	const resultOnContextMenu = useQuickFindResultOnContextMenu();
	const resultOnHighlighted = useQuickFindResultOnHighlighted();
	const footerOnClick = useQuickFindFooterOnClick();

	const body = [];

	for (const content of allItems) {
		if (isErrorItem(content)) {
			body.push(<ErrorScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isLandingItem(content)) {
			body.push(<LandingScreen key={content.id} />);
		}

		if (isNoResultsItem(content)) {
			body.push(<NoResultsScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isHeadingItem(content)) {
			body.push(
				<Heading
					key={content.id}
					id={content.id}
					text={content.data}
					includeInAnalytics={content.includeInAnalytics}
				/>,
			);
		}

		if (isSkeletonItem(content)) {
			body.push(<SearchResultSkeleton key={content.id} />);
		}

		if (isRecentQueryItem(content)) {
			body.push(<RecentQuery key={content.id} result={content.data} state={state} />);
		}

		if (isRecentActivityItem(content)) {
			body.push(
				<RecentActivity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}

		if (isSearchResultItem(content)) {
			body.push(
				<ProductEntity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}

		if (isPeopleAndTeamsItem(content)) {
			body.push(
				<PeopleAndTeams
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}
	}

	const footer =
		primaryProduct === PrimaryProductKeys.Confluence && isPostQueryState(state) ? (
			<>
				<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				<Divider />
				<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
				<Divider />
				<FeedbackFooter />
			</>
		) : (
			<>
				<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				<ShowIfEligible requiredMode={ApplicationModes.Unified}>
					<Divider />
					<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				<Divider />
				<FeedbackFooter />
			</>
		);

	return (
		<Box
			xcss={searchDialogStyles}
			aria-label={intl.formatMessage(messages.searchDialogAriaLabel)}
			testId={'search-dialog'}
			ref={dialogRef}
			id={dialogId}
		>
			<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
				<RovoTipBanner />
			</ShowIfEligible>
			<Box xcss={searchResultsSectionStyles}>{body}</Box>
			{footer}
		</Box>
	);
};

const useContent = (content: QuickFindContent) => {
	const intl = useIntl();

	const { state } = content;

	return useMemo(() => {
		const items: Item[] = [];

		const recentsHeadingItem = {
			id: 'recent-activities',
			type: 'heading' as const,
			data: intl.formatMessage(messages.recentsHeading),
		};

		const searchResultsHeadingItem = {
			id: 'search-results',
			type: 'heading' as const,
			data: intl.formatMessage(messages.searchResultsHeading),
		};

		if (state === State.PREQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.PREQUERY_LOADING) {
			items.push(...content.recentQueries.slice(0, 3));
			items.push(recentsHeadingItem);
			for (let i = 0; i < 7; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.PREQUERY_NO_RESULTS) {
			items.push({ id: 'landing', type: 'landing' });
		}

		if (state === State.PREQUERY_RESULTS) {
			items.push(...content.recentQueries.slice(0, 3));
			items.push(recentsHeadingItem);
			items.push(...content.recentActivities.slice(0, 7));
		}

		if (state === State.POSTQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.POSTQUERY_LOADING) {
			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				items.push(...content.recentActivities.slice(0, 5));
			}

			items.push({ ...searchResultsHeadingItem, includeInAnalytics: false });
			for (let i = 0; i < 5; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.POSTQUERY_NO_RESULTS) {
			items.push({ id: 'no-results', type: 'no-results' });
		}

		if (state === State.POSTQUERY_RESULTS) {
			const dedupedSearchResults = content.searchResults.filter((searchResult) => {
				const isDuplicate = content.recentActivities
					.slice(0, 5)
					.some((recentlyViewedResult) => recentlyViewedResult.id === searchResult.id);
				return searchResult.id && !isDuplicate;
			});
			const filteredSearchResults = dedupedSearchResults.filter(
				(result) => !isSearchConfluenceSpace(result.data),
			);

			const confluenceSpaceResult = dedupedSearchResults.find((result) =>
				isSearchConfluenceSpace(result.data),
			);

			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				items.push(...content.recentActivities.slice(0, 5));
			}

			const showSearchResultsSection = Boolean(
				dedupedSearchResults.length + content.peopleAndTeams.length + content.recentQueries.length,
			);

			if (showSearchResultsSection) {
				items.push(searchResultsHeadingItem);
				items.push(...filteredSearchResults.slice(0, confluenceSpaceResult ? 5 : 6));
				confluenceSpaceResult && items.push(confluenceSpaceResult);
				items.push(...content.peopleAndTeams.slice(0, 1));
				items.push(...content.recentQueries.slice(0, 1));
			}
		}

		return items;
	}, [content, intl, state]);
};

const useAnalyticsEvents = ({ state }: { state: State }) => {
	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const previousStateRef = useRef<State | undefined>();

	useEffect(() => {
		fireAnalyticsEvent(
			onSearchDialogShown({ attributes: commonAttributes, nonPrivacySafeAttributes }),
		);

		return () => {
			fireAnalyticsEvent(
				onSearchDialogDismissed({ attributes: commonAttributes, nonPrivacySafeAttributes }),
			);
		};
		// Only want these events to fire when the dialog opens and closes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state === previousStateRef.current) {
			return;
		}

		previousStateRef.current = state;

		const { sectionCount, resultCount } = getSectionsAndResultsCount();

		const resultsType =
			state === State.PREQUERY_RESULTS || state === State.PREQUERY_NO_RESULTS
				? 'preQuerySearchResults'
				: state === State.POSTQUERY_LOADING && resultCount
					? 'cachedResults'
					: state === State.POSTQUERY_RESULTS || state === State.POSTQUERY_NO_RESULTS
						? 'postQuerySearchResults'
						: undefined;

		if (resultsType === undefined) {
			return;
		}

		fireAnalyticsEvent(
			onSearchResultsShown({
				attributes: {
					...commonAttributes,
					sectionCount,
					resultCount,
					noResultsFetched: resultCount === 0,
				},
				nonPrivacySafeAttributes,
				resultsType,
			}),
		);
	}, [commonAttributes, fireAnalyticsEvent, nonPrivacySafeAttributes, state]);
};

const useResetSessionAnalytics = () => {
	const { refreshSearchSessionId } = useBootstrapActions();
	const { resetQueryVersion } = useSearchActions();

	useEffect(() => {
		refreshSearchSessionId();
		resetQueryVersion();
	}, [refreshSearchSessionId, resetQueryVersion]);
};
