import React, { useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import ClockIcon from '@atlaskit/icon/core/clock';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { Box, Inline } from '@atlaskit/primitives';
import { addRecentSearch, RECENT_SEARCH_KEY, removeRecentSearch } from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { useAppContext } from '../../../../common/ui/app-context';
import { SearchResult } from '../../../../common/ui/quick-find/search-dialog-result';
import { onRecentQuerySelected } from '../../../../common/utils/quick-find/events/recent-query-selected';
import { type State } from '../../../../common/utils/quick-find/state';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../../controllers/quick-find/analytics';
import { getActionSubjectId } from '../../../../controllers/quick-find/utils';
import { usePrimaryProduct, useSearchSessionId, useUser } from '../../../../controllers/store';
import { useUpdateRecentQueries } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

type Props = {
	result: string;
	state: State;
};

export const RecentQuery = ({ result, state }: Props) => {
	const intl = useIntl();

	const { generateSearchUrl, queryParams } = useAppContext();

	const [primaryProduct] = usePrimaryProduct();
	const [searchSessionId] = useSearchSessionId();
	const [user] = useUser();
	const updateRecentQueries = useUpdateRecentQueries();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const onResultClicked = useCallback(() => {
		// Move this recent query to the top of the list
		if (result && user && user.id && primaryProduct) {
			addRecentSearch(result, user.id, primaryProduct, RECENT_SEARCH_KEY);
			updateRecentQueries?.();
		}

		fireAnalyticsEvent(
			onRecentQuerySelected({
				actionSubjectId: getActionSubjectId(state),
				attributes: commonAttributes,
				nonPrivacySafeAttributes,
			}),
		);
	}, [
		commonAttributes,
		fireAnalyticsEvent,
		nonPrivacySafeAttributes,
		primaryProduct,
		result,
		state,
		updateRecentQueries,
		user,
	]);

	const mapRecentQueryToSearchResult = useCallback(
		(query: string) => {
			const id = query;

			const icon = (
				<ClockIcon label={intl.formatMessage(messages.recentSearch)} color="currentColor" />
			);

			const title = query;

			const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				event.preventDefault();
				event.stopPropagation();
				if (user.id && primaryProduct) {
					removeRecentSearch(query, user.id, primaryProduct, RECENT_SEARCH_KEY);
					updateRecentQueries?.();
				}
			};

			const rightMetadata = <Box>{intl.formatMessage(messages.recentSearch)}</Box>;

			const hoveredRightMetadata = (
				<Inline alignBlock="center" space="space.100">
					<Box>{intl.formatMessage(messages.recentSearch)}</Box>
					<IconButton
						icon={CrossIcon}
						spacing={'compact'}
						onClick={onClick}
						label={intl.formatMessage(messages.recentSearch)}
					/>
				</Inline>
			);

			const highlightedRightMetadata = (
				<Inline alignBlock="center" space="space.100">
					<Box>{intl.formatMessage(messages.recentSearch)}</Box>
					<ReturnIcon />
				</Inline>
			);

			const url = generateSearchUrl({
				...queryParams,
				text: query,
				search_id: searchSessionId,
			});

			return {
				id,
				icon,
				title,
				url,
				rightMetadata,
				hoveredRightMetadata,
				highlightedRightMetadata,
			};
		},
		[
			intl,
			generateSearchUrl,
			queryParams,
			searchSessionId,
			user.id,
			primaryProduct,
			updateRecentQueries,
		],
	);

	return (
		<SearchResult
			{...mapRecentQueryToSearchResult(result)}
			onResultClicked={onResultClicked}
			includeInAnalytics={false}
		/>
	);
};
