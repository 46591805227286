import { type UserSearchItem } from '@atlaskit/smart-common';
import { type QuickFindAllActivityDataEdge, type SearchPageNode } from '@atlassian/search-client';

export type QuickFindResult<T> = {
	id: string;
	title: string;
	data: T;
	type: string;
};

export type RecentQueries = QuickFindResult<string>[];

export type RecentActivities = {
	result: QuickFindResult<QuickFindAllActivityDataEdge>[];
	duration: number;
};

export type SearchResults = {
	result: QuickFindResult<SearchPageNode>[];
	duration: number;
};

export type PeopleAndTeams = {
	result: QuickFindResult<UserSearchItem>[];
	duration: number;
};

export type QuickFindContent =
	| {
			state: 'PREQUERY_ERROR';
	  }
	| {
			state: 'PREQUERY_LOADING';
			recentQueries: QuickFindResult<string>[];
	  }
	| {
			state: 'PREQUERY_NO_RESULTS';
	  }
	| {
			state: 'PREQUERY_RESULTS';
			recentQueries: QuickFindResult<string>[];
			recentActivities: QuickFindResult<QuickFindAllActivityDataEdge>[];
	  }
	| {
			state: 'POSTQUERY_ERROR';
	  }
	| {
			state: 'POSTQUERY_LOADING';
			recentActivities: QuickFindResult<QuickFindAllActivityDataEdge>[];
	  }
	| {
			state: 'POSTQUERY_NO_RESULTS';
	  }
	| {
			state: 'POSTQUERY_RESULTS';
			recentQueries: QuickFindResult<string>[];
			recentActivities: QuickFindResult<QuickFindAllActivityDataEdge>[];
			searchResults: QuickFindResult<SearchPageNode>[];
			peopleAndTeams: QuickFindResult<UserSearchItem>[];
	  };

type StateHandlerArgs = {
	hasQuery: boolean;
	preQueryError?: Error;
	preQueryLoading: boolean;
	postQueryError?: Error;
	postQueryLoading: boolean;
	filteredRecentQueries: RecentQueries;
	filteredRecentActivities: RecentActivities;
	searchResults: SearchResults;
	peopleAndTeams: PeopleAndTeams;
};

const preQueryErrorHandler = ({
	hasQuery,
	preQueryError,
}: StateHandlerArgs): QuickFindContent | undefined => {
	if (!hasQuery && preQueryError) {
		return {
			state: 'PREQUERY_ERROR',
		};
	}
};

const preQueryLoadingHandler = ({
	hasQuery,
	preQueryLoading,
	filteredRecentQueries,
}: StateHandlerArgs): QuickFindContent | undefined => {
	if (!hasQuery && preQueryLoading) {
		return {
			state: 'PREQUERY_LOADING',
			recentQueries: filteredRecentQueries,
		};
	}
};

const preQueryNoResultsHandler = ({
	hasQuery,
	filteredRecentQueries,
	filteredRecentActivities,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const totalResults = filteredRecentQueries.length + filteredRecentActivities.result.length;

	if (!hasQuery && totalResults === 0) {
		return {
			state: 'PREQUERY_NO_RESULTS',
		};
	}
};

const preQueryResultsHandler = ({
	hasQuery,
	filteredRecentQueries,
	filteredRecentActivities,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const totalResults = filteredRecentQueries.length + filteredRecentActivities.result.length;

	if (!hasQuery && totalResults > 0) {
		return {
			state: 'PREQUERY_RESULTS',
			recentQueries: filteredRecentQueries,
			recentActivities: filteredRecentActivities.result,
		};
	}
};

const postQueryErrorHandler = ({
	hasQuery,
	postQueryError,
}: StateHandlerArgs): QuickFindContent | undefined => {
	if (hasQuery && postQueryError) {
		return {
			state: 'POSTQUERY_ERROR',
		};
	}
};

const postQueryLoadingHandler = ({
	hasQuery,
	postQueryLoading,
	filteredRecentActivities,
}: StateHandlerArgs): QuickFindContent | undefined => {
	if (hasQuery && postQueryLoading) {
		return {
			state: 'POSTQUERY_LOADING',
			recentActivities: filteredRecentActivities.result,
		};
	}
};

const postQueryNoResultsHandler = ({
	hasQuery,
	filteredRecentQueries,
	filteredRecentActivities,
	searchResults,
	peopleAndTeams,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const totalResults =
		filteredRecentQueries.length +
		filteredRecentActivities.result.length +
		searchResults.result.length +
		peopleAndTeams.result.length;

	if (hasQuery && totalResults === 0) {
		return {
			state: 'POSTQUERY_NO_RESULTS',
		};
	}
};

const postQueryResultsHandler = ({
	hasQuery,
	filteredRecentQueries,
	filteredRecentActivities,
	searchResults,
	peopleAndTeams,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const totalResults =
		filteredRecentQueries.length +
		filteredRecentActivities.result.length +
		searchResults.result.length +
		peopleAndTeams.result.length;

	if (hasQuery && totalResults > 0) {
		return {
			state: 'POSTQUERY_RESULTS',
			recentQueries: filteredRecentQueries,
			recentActivities: filteredRecentActivities.result,
			searchResults: searchResults.result,
			peopleAndTeams: peopleAndTeams.result,
		};
	}
};

export const getQuickFindContent = (args: StateHandlerArgs): QuickFindContent => {
	const handlers = [
		preQueryErrorHandler,
		preQueryLoadingHandler,
		preQueryNoResultsHandler,
		preQueryResultsHandler,
		postQueryErrorHandler,
		postQueryLoadingHandler,
		postQueryNoResultsHandler,
		postQueryResultsHandler,
	];

	for (const handler of handlers) {
		const result = handler(args);

		if (result) {
			return result;
		}
	}

	// This should never happen, but default to error state
	if (!args.hasQuery) {
		return {
			state: 'PREQUERY_ERROR',
		};
	} else {
		return {
			state: 'POSTQUERY_ERROR',
		};
	}
};
