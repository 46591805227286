import {
	type Action,
	createActionsHook,
	createContainer,
	createHook,
	createStateHook,
	createStore,
} from 'react-sweet-state';

export type QuickFindState = {
	quickFindOpen: boolean;
	isFeedbackModalOpen: boolean;
	retryCount: number;
	updateRecentQueries?: () => void;
};

export const initialState = {
	quickFindOpen: false,
	isFeedbackModalOpen: false,
	retryCount: 0,
	updateRecentQueries: undefined,
} satisfies QuickFindState;

const actions = {
	setQuickFindOpen:
		(quickFindOpen: QuickFindState['quickFindOpen']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ quickFindOpen });
		},
	setIsFeedbackModalOpen:
		(feedbackOpen: QuickFindState['isFeedbackModalOpen']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ isFeedbackModalOpen: feedbackOpen });
		},
	setRetryCount:
		(retryCount: QuickFindState['retryCount']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ retryCount });
		},
	setUpdateRecentQueries:
		(updateRecentQueries: QuickFindState['updateRecentQueries']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ updateRecentQueries });
		},
};

type TActions = typeof actions;

const Store = createStore<QuickFindState, TActions>({
	initialState,
	actions,
	name: 'quick-find',
});

export const useQuickFindActions = createActionsHook(Store);

export const useQuickFindOpen = createStateHook(Store, {
	selector: (state) => state.quickFindOpen,
});

export const useIsFeedbackModalOpen = createHook(Store, {
	selector: (state) => state.isFeedbackModalOpen,
});

export const useRetryCount = createStateHook(Store, { selector: (state) => state.retryCount });

export const useUpdateRecentQueries = createStateHook(Store, {
	selector: (state) => state.updateRecentQueries,
});

/**
 * This store is contained within a container as we may need to support multiple
 * instances of the Quick Find component in Nav3. This would be the case if we had
 * one in the navigation bar and another one in the search page.
 */
export const QuickFindStoreContainer = createContainer(Store);
