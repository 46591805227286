import React, { type PropsWithChildren, useEffect, useRef, useState } from 'react';

import { bindAll } from 'bind-event-listener';

// eslint-disable-next-line @atlaskit/design-system/no-banned-imports
import { useId } from '@atlaskit/ds-lib/use-id';
import { Box, xcss } from '@atlaskit/primitives';
import { KeyboardHighlightProvider } from '@atlassian/search-dialog';

import { AppContextProvider, type AppContextProviderProps } from '../../common/ui/app-context';
import { useQuickFindResultsWithAnalytics } from '../../controllers/quick-find/fetch-results';
import {
	type BootstrapContainerProps,
	BootstrapStoreProvider,
} from '../../controllers/store/bootstrap/bootstrap-store-provider';
import {
	useIsFeedbackModalOpen,
	useQuickFindActions,
	useQuickFindOpen,
} from '../../controllers/store/quick-find';
import { QuickFindStoreProvider } from '../../controllers/store/quick-find/quick-find-store-provider';
import { SearchStoreProvider } from '../../controllers/store/search/search-store-provider';

import { SearchDialog } from './dialog';
import { SearchTextField } from './textfield';
import { useSyncStates } from './utils';

const textFieldBoxStyles = xcss({
	width: '100%',
	position: 'relative',
});

type QuickFindComponentProps = {
	open?: boolean;
	setOpen?: (open: boolean) => void;
};

type QuickFindProps = BootstrapContainerProps & AppContextProviderProps & QuickFindComponentProps;

const QuickFindComponent = ({ open, setOpen }: QuickFindComponentProps) => {
	const inputBoxRef = useRef<HTMLDivElement>(null);
	const dialogRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isFeedbackModalOpen] = useIsFeedbackModalOpen();

	const dialogId = useId();

	const { setQuickFindOpen } = useQuickFindActions();
	const quickFindOpen = useQuickFindOpen();

	useSyncStates(quickFindOpen, setQuickFindOpen, open, setOpen);

	const content = useQuickFindResultsWithAnalytics();

	useEffect(() => {
		const onClick = (event: MouseEvent | React.MouseEvent) => {
			const { target } = event;
			const doesDomNodeExist = document.body.contains(target as Node);

			if (!doesDomNodeExist || isFeedbackModalOpen) {
				return;
			}

			const isClickOnPopup = dialogRef.current && dialogRef.current.contains(target as Node);
			const isClickOnTrigger = inputBoxRef.current && inputBoxRef.current.contains(target as Node);

			if (!isClickOnPopup && !isClickOnTrigger) {
				setQuickFindOpen(false);
			}
		};

		const onKeyDown = (event: KeyboardEvent | React.KeyboardEvent) => {
			const { key } = event;
			if (key === 'Escape' || key === 'Esc') {
				setQuickFindOpen(false);
			}
		};

		const unbind = bindAll(window, [
			{
				type: 'click',
				listener: onClick,
				options: { capture: false },
			},
			{
				type: 'keydown',
				listener: onKeyDown,
			},
		]);

		return () => {
			unbind();
		};
	}, [setQuickFindOpen, isFeedbackModalOpen]);

	return (
		<Box xcss={textFieldBoxStyles} ref={inputBoxRef}>
			<KeyboardHighlightProvider listenerNode={inputRef?.current}>
				<SearchTextField dialogId={dialogId} inputRef={inputRef} state={content.state} />
				{quickFindOpen && (
					<SearchDialog dialogId={dialogId} dialogRef={dialogRef} content={content} />
				)}
			</KeyboardHighlightProvider>
		</Box>
	);
};

const QuickFindShell = ({
	children,
	linkComponent,
	mapUserIdToProfileUrl,
	queryParams: actualQueryParams,
	...props
}: PropsWithChildren<QuickFindProps>) => {
	/**
	 * Quick Find does not need to set queryParams, hence using an object
	 * in a state variable as the queryParams object. This object is the source
	 * of truth for the inputQuery in the QuickFind component.
	 */
	const [queryParams, setQueryParams] = useState<{ text?: string }>({});

	/**
	 * We only update the internal queryParam state above when the
	 * actualQueryParams.text changes. This is protects us from the posiblity
	 * that queryParams might be a object with an unstable reference.
	 */
	const previousTextQueryParam = useRef<string | undefined>();
	useEffect(() => {
		if (actualQueryParams?.text === previousTextQueryParam.current) {
			return;
		}

		setQueryParams((previousQueryParams) => ({
			...previousQueryParams,
			text: actualQueryParams?.text,
		}));

		previousTextQueryParam.current = actualQueryParams?.text;
	}, [actualQueryParams?.text, queryParams?.text]);

	return (
		<BootstrapStoreProvider
			{...props}
			queryParams={queryParams}
			callbacks={{ updateQueryParameters: setQueryParams }}
		>
			<SearchStoreProvider>
				<QuickFindStoreProvider>
					<AppContextProvider {...props} queryParams={queryParams}>
						{children}
					</AppContextProvider>
				</QuickFindStoreProvider>
			</SearchStoreProvider>
		</BootstrapStoreProvider>
	);
};

export const QuickFind = ({ children, ...props }: PropsWithChildren<QuickFindProps>) => {
	return (
		<QuickFindShell {...props}>
			<QuickFindComponent {...props} />
		</QuickFindShell>
	);
};
