import React, { type RefObject, useCallback, useEffect, useState } from 'react';

import { addRecentSearch, RECENT_SEARCH_KEY } from '@atlassian/search-common';
import { useKeyboardHighlightProvider } from '@atlassian/search-dialog';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { useAppContext } from '../../../common/ui/app-context';
import { Textfield } from '../../../common/ui/quick-find/textfield';
import { onAdvancedSearchLinkSelected } from '../../../common/utils/quick-find/events/advanced-search-link-selected';
import { onTextEntered } from '../../../common/utils/quick-find/events/text-entered';
import { State } from '../../../common/utils/quick-find/state';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import {
	useApplicationMode,
	useBootstrap,
	useFeatures,
	useSearchActions,
} from '../../../controllers/store';
import { useBootstrapActions } from '../../../controllers/store/bootstrap';
import {
	useQuickFindActions,
	useQuickFindOpen,
	useUpdateRecentQueries,
} from '../../../controllers/store/quick-find';

import { getInitialQuery, useInputSkeleton } from './input-skeleton';

export type SearchTextFieldProps = {
	dialogId: string;
	inputRef?: RefObject<HTMLInputElement>;
	state: State;
};

export const SearchTextField = ({ dialogId, inputRef, state }: SearchTextFieldProps) => {
	const { generateSearchUrl, onNavigate, queryParams } = useAppContext();

	const [{ primaryProduct, user, isNav4Enabled }] = useBootstrap();
	const [features] = useFeatures();
	const quickFindOpen = useQuickFindOpen();
	const { getSelectedIndex } = useKeyboardHighlightProvider();
	const updateRecentQueries = useUpdateRecentQueries();
	const applicationMode = useApplicationMode();

	const { setQueryParams } = useBootstrapActions();
	const { setQuickFindOpen } = useQuickFindActions();
	const { setQueryUpdatedTime } = useSearchActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const [localQuery, setLocalQuery] = useState(getInitialQuery);

	useInputSkeleton({
		localQuery,
		inputRef,
	});

	// Sync local query with input query
	useEffect(() => {
		setQueryParams({ text: localQuery });
	}, [setQueryParams, localQuery]);

	// Update query time when query changes
	useEffect(() => {
		setQueryUpdatedTime();
	}, [setQueryUpdatedTime, localQuery]);

	// Sync search query with local query e.g
	//  - when the page loads with a query in query parameters
	//  - when smart answers suggest follow up queries
	useEffect(() => {
		if (typeof queryParams.text === 'string') {
			setLocalQuery(queryParams.text);
		}
	}, [queryParams, setLocalQuery]);

	useEffect(() => {
		if (quickFindOpen) {
			inputRef?.current?.focus();
		} else {
			inputRef?.current?.blur();
		}
	}, [inputRef, quickFindOpen]);

	const onKeyPress = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				if (!primaryProduct) {
					return;
				}

				// Store search in recent searches
				if (localQuery.trim() !== '' && user && user.id && primaryProduct) {
					addRecentSearch(localQuery.trim(), user.id, primaryProduct, RECENT_SEARCH_KEY);
					updateRecentQueries?.();
				}

				// Don't navigate to the search page if enter is pressed whilst a result
				// is highlighted. The keyboard event is handled by the search result.
				const resultHighlighted = getSelectedIndex && getSelectedIndex() !== undefined;
				if (resultHighlighted) {
					return;
				}

				if (features?.chromeExtension?.enabled) {
					features.chromeExtension.onInputSubmit({ query: localQuery });
				}

				event.currentTarget.blur();
				setQuickFindOpen(false);

				fireAnalyticsEvent(
					onAdvancedSearchLinkSelected({
						primaryProduct,
						attributes: {
							...commonAttributes,
							trigger: 'return',
							newTab: false,
							isLoading: state === State.PREQUERY_LOADING || state === State.POSTQUERY_LOADING,
						},
						nonPrivacySafeAttributes,
					}),
				);

				const searchForPrimaryProduct =
					(applicationMode === ApplicationModes.Rovo && (event.metaKey || event.ctrlKey)) ||
					(applicationMode !== ApplicationModes.Rovo && !(event.metaKey || event.ctrlKey));

				if (searchForPrimaryProduct) {
					onNavigate(
						generateSearchUrl({
							...queryParams,
							product: primaryProduct,
							text: localQuery,
						}),
						'push',
					);
				} else {
					onNavigate(
						generateSearchUrl({
							...queryParams,
							text: localQuery,
						}),
						'push',
					);
				}
			}

			if (event.key === 'Escape') {
				event.currentTarget.blur();
				setQuickFindOpen(false);
			}
		},
		[
			applicationMode,
			commonAttributes,
			features?.chromeExtension,
			fireAnalyticsEvent,
			generateSearchUrl,
			getSelectedIndex,
			localQuery,
			nonPrivacySafeAttributes,
			onNavigate,
			primaryProduct,
			queryParams,
			setQuickFindOpen,
			state,
			updateRecentQueries,
			user,
		],
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setLocalQuery(event.currentTarget.value);

			fireAnalyticsEvent(
				onTextEntered({
					attributes: {
						...commonAttributes,
						queryUpdatedAgo: 0, // Query just updated
					},
					nonPrivacySafeAttributes: { query: event.currentTarget.value },
				}),
			);
		},
		[commonAttributes, fireAnalyticsEvent],
	);

	return (
		<Textfield
			value={localQuery}
			dialogId={dialogId}
			inputRef={inputRef}
			appMode={applicationMode}
			quickFindOpen={quickFindOpen}
			isNav4Enabled={isNav4Enabled}
			onFocus={() => setQuickFindOpen(true)}
			onKeyDown={onKeyPress}
			onChange={onChange}
			onClear={() => {
				setLocalQuery('');
				inputRef?.current?.focus();
			}}
		/>
	);
};
