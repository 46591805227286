import React from 'react';

import {
	type QuickFindSearchResultType,
	type QuickFindSearchMercuryResult,
} from '@atlassian/search-client';
import { transformAriToId } from '@atlassian/search-common';
import Image from '@atlaskit/image';
import { capitalizeFirstLetter } from './quick-find-meta';

const getIcon = (result?: QuickFindSearchMercuryResult) => {
	return result?.iconUrl ? (
		<Image height={24} width={24} title={result.type} src={result.iconUrl} />
	) : (
		<></>
	);
};

export const quickFindMercuryContentMapper = ({
	node: item,
}: {
	node?: QuickFindSearchMercuryResult;
}): QuickFindSearchResultType => {
	return {
		id: transformAriToId(item?.id || '', item?.type || ''),
		title: item?.title || '',
		url: item?.url || '',
		meta: capitalizeFirstLetter(item?.type || ''),
		additionalMeta: '',
		containerId: '',
		type: `mercury ${item?.type}` || '',
		icon: getIcon(item),
	};
};
