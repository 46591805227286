import {
	type EntityATI,
	type ExperimentLayer,
	type SearchPageNode,
	searchPageQuery,
	type SearchPageQueryVariables,
} from '@atlassian/search-client';

import { type PrimaryProductKey } from '../../../../common/constants/products';

export const fetchConfluenceEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
) => {
	const variables = {
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 5,
		commonFilters: {},
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
			titleMatchOnly: true,
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities: [
			'ati:cloud:confluence:page',
			'ati:cloud:confluence:blogpost',
			'ati:cloud:confluence:attachment',
			'ati:cloud:confluence:whiteboard',
			'ati:cloud:confluence:database',
		] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchPageQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return nodes.map(mapEssentialData);
};

export const fetchConfluenceSpaces = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
) => {
	const variables = {
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 1,
		commonFilters: {},
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities: ['ati:cloud:confluence:space'] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchPageQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return nodes.map(mapEssentialData);
};

export const mapEssentialData = (result: SearchPageNode) => ({
	id: result.id,
	title: result.title,
	data: result,
	type: 'search-result' as const,
});
