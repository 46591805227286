import React, { type ReactElement, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';
import type ApolloClient from 'apollo-client';
import {
	useQuickFindItemSupplier,
	useQuickFindPostQueryItemSupplier,
} from '@atlassian/search-client';
import { EnlargedSearchResultContent } from '@atlassian/search-dialog';
import { fg } from '@atlaskit/platform-feature-flags';

import { type ExtensibleTabProps, SearchDialogProduct, type SearchResult } from '../../..';
import { messages as commonMessages } from '../../../messages';
import { messages } from '../messages';
import { EMPTY_SEARCH_ITEMS, type Section } from '../../product-router/product';
import { type ConfluenceRecentsClientConfig } from '../../products/confluence/types';
import { QuickFindResultRenderer } from '../quick-find-result-renderer';
import { QuickFindConfluenceTransformer } from './transformers/quick-find-confluence-transformer';
import { PersistRecentSearch } from '../../recent-searches/persist-recent-search';
import { type AdvancedSearchUrlGenerator } from '../../product-router/product-router-state';
import { quickFindRecentItemResultMapper } from './mappers/quick-find-recent-item-mapper';
import { quickFindRecentSpaceResultMapper } from './mappers/quick-find-recent-space-mapper';
import { getQuickFindPeopleAndTeamsResultMapper } from './mappers/quick-find-people-and-teams-mapper';
import { quickFindConfluenceSpaceMapper } from './mappers/quick-find-confluence-space-mapper';
import { quickFindConfluenceContentMapper } from './mappers/quick-find-confluence-content-mapper';
import { useUserContext } from '../../../common/user-context';
import { quickFindAtlasContentMapper } from './mappers/quick-find-atlas-content-mapper';
import { useExperiments } from '../../../common/experiment-provider';
import { useQueryParams } from '../../products/confluence/confluence-analytics-query-params';
import { useFeaturesContext } from '../../features-context/features-context';
import { quickFindMercuryContentMapper } from './mappers/quick-find-mercury-content-mapper';

export const ProductKeys = {
	Confluence: 'confluence',
	Atlas: 'townsquare',
	Mercury: 'mercury',
} as const;

export type ProductKey = (typeof ProductKeys)[keyof typeof ProductKeys];

export const quickFindSearchResultRenderer = ({
	searchResult,
	isKeyboardHighlighted,
}: {
	searchResult: SearchResult;
	isKeyboardHighlighted: boolean;
}) => {
	return (
		<PersistRecentSearch>
			<EnlargedSearchResultContent
				{...searchResult}
				isKeyboardHighlighted={isKeyboardHighlighted}
				icon={React.isValidElement(searchResult.icon) ? searchResult.icon : <></>}
			/>
		</PersistRecentSearch>
	);
};

// QS-3240 omitting props as we move QS to a platformised QF
// TODO: this typing should be cleaned up to be QF specific
export type QuickFindConfluenceProps = Omit<
	ExtensibleTabProps,
	'order' | 'preQueryItemSupplier' | 'postQueryItemSupplier'
> & {
	recentClientConfig: ConfluenceRecentsClientConfig;
	formatDate?: (lastModified: string) => ReactElement;
	confCloudClient?: Pick<ApolloClient<object>, 'query'>; // TODO: QS-3541 replace with a fetch from search-client
	onPreQueryErrorCallback?: (error: any) => void;
	aggAbsoluteUrl?: string;
	cloudId: string;
	orgId?: string | null;
	primaryProduct: ProductKey;
};

export const QuickFindConfluence: React.FC<QuickFindConfluenceProps> = ({
	onRetry,
	linkComponent,
	confCloudClient,
	aggAbsoluteUrl,
	onPreQueryErrorCallback,
	cloudId,
	orgId,
	primaryProduct,
}) => {
	const { user } = useUserContext();
	const features = useFeaturesContext();
	const { formatMessage } = useIntl();
	const appendAnalyticsQueryParams = useQueryParams();
	const isEnhancedSearchAnalyticsEnabled = fg('cc_page_experiences_enhanced_search_analytics_conf');
	const appendQueryParams =
		isEnhancedSearchAnalyticsEnabled && primaryProduct === 'confluence'
			? appendAnalyticsQueryParams
			: undefined;

	const preQueryItemSupplier = useQuickFindItemSupplier({
		confCloudClient,
		aggAbsoluteUrl,
		markErrorAsHandled: onPreQueryErrorCallback,
		itemRenderer: quickFindSearchResultRenderer,
		recentContentResultMapper: quickFindRecentItemResultMapper,
		recentSpaceResultMapper: quickFindRecentSpaceResultMapper,
		userId: user.id,
		cloudId,
	});

	// This currently isn't being used for results fetching or rendering. It is
	// only being used to create an array of sections which are then used for
	// product registration. After product registration, these scopes are then
	// used to call the experiment endpoint, for this reason they need to be
	// valid scopes for each product.
	const defaultSections: Section[] = useMemo(() => {
		switch (primaryProduct) {
			case ProductKeys.Confluence:
				return [
					{
						id: 'confluence.page',
						title: '',
					},
				];
			case ProductKeys.Atlas:
				return [
					{
						id: 'townsquare.goal',
						title: '',
					},
				];
			case ProductKeys.Mercury:
				return [
					{
						id: 'mercury.focusarea',
						title: '',
					},
				];
			default:
				return [];
		}
	}, [primaryProduct]);

	const { backendExperiment } = useExperiments();
	const { experimentId, shadowExperimentId, experimentLayers } = backendExperiment || {};

	const quickFindPeopleAndTeamsResultMapper = getQuickFindPeopleAndTeamsResultMapper(
		primaryProduct,
		orgId || '',
		cloudId,
	);

	const quickFindPostQueryItemSupplier = useQuickFindPostQueryItemSupplier({
		cloudId,
		resultRenderer: quickFindSearchResultRenderer,
		confluenceResultMapper: quickFindConfluenceContentMapper,
		atlasResultMapper: quickFindAtlasContentMapper,
		mercuryResultMapper: quickFindMercuryContentMapper,
		spaceResultMapper: quickFindConfluenceSpaceMapper,
		peopleAndTeamsResultMapper: quickFindPeopleAndTeamsResultMapper,
		sectionTitle: formatMessage(messages.quick_find_results_section),
		userId: user.id,
		orgId,
		aggAbsoluteUrl,
		primaryProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers: experimentLayers?.map(({ name, layerId, shadowId }) => ({
			name,
			layerId,
			shadowId,
		})),
	});

	const noResultsPostQueryItemSupplier = useCallback(() => Promise.resolve(EMPTY_SEARCH_ITEMS), []);

	const postQueryItemSupplier = features?.quickFind?.postQueryDisabled
		? noResultsPostQueryItemSupplier
		: quickFindPostQueryItemSupplier;

	// TODO: baseUrl is a holdover from QS and not needed in QF
	const generateAdvancedSearchUrl: AdvancedSearchUrlGenerator = useCallback(
		(query, baseUrl, usage) => {
			let url = '';
			const params = new URLSearchParams();

			const trimmedQuery = query.trim();

			if (trimmedQuery) {
				params.append('text', trimmedQuery);
			}

			if (primaryProduct === ProductKeys.Confluence) {
				url = '/wiki/search/';

				if (usage === 'onInputEnterModified') {
					params.append('product', 'confluence');
				}
			} else if (primaryProduct === ProductKeys.Atlas) {
				url = `/o/${orgId}/search/`;

				params.append('cloudId', cloudId);
			} else if (primaryProduct === ProductKeys.Mercury) {
				url = '/focus/search/';
			}

			// URLSearchParams's toString will do encode special characters, however spaces
			// are encoded as +. We need to replace them with %20 to match existing behaviour.
			url += `?${params.toString()}`;
			url = url.replace(/\+/g, '%20');

			return url;
		},
		[cloudId, orgId, primaryProduct],
	);

	const title = useMemo(() => {
		switch (primaryProduct) {
			case ProductKeys.Confluence:
				return formatMessage(commonMessages.confluence_tab_label);
			case ProductKeys.Atlas:
				return formatMessage(commonMessages.atlas_tab_label);
			case ProductKeys.Mercury:
				return formatMessage(commonMessages.mercury_tab_label);

			default:
				return '';
		}
	}, [formatMessage, primaryProduct]);

	return (
		<SearchDialogProduct
			permissionSupplier={() => Promise.resolve(defaultSections.map((s) => s.id))}
			sections={defaultSections}
			id={primaryProduct}
			title={title}
			order={0}
			expandedStateInputPlaceholder={formatMessage(messages.quick_find_search_input_placeholder)}
			preQueryItemSupplier={preQueryItemSupplier}
			postQueryItemSupplier={postQueryItemSupplier}
			onRetry={onRetry}
			ResultRenderer={QuickFindResultRenderer}
			SearchItemsTransformer={QuickFindConfluenceTransformer}
			isPrefetchingEnabled
			generateAdvancedSearchUrl={generateAdvancedSearchUrl}
			searchFooterLabel={'Search your organization'}
			linkComponent={linkComponent}
			appendQueryParams={appendQueryParams}
		/>
	);
};
