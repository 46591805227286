// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N200 } from '@atlaskit/theme/colors';
import { grid } from '../style-utils';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultSectionContainer = styled.div({
	padding: `${token('space.100', '8px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedSearchResultSectionContainer = styled.div({
	padding: 0,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultSectionHeading = styled.div({
	marginBottom: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: grid.multiple(4).px,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedSearchResultSectionHeading = styled.div({
	height: '28px',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultsSectionTitle = styled.h2({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: grid.twice().px,
	fontWeight: token('font.weight.semibold'),
	textTransform: 'uppercase',
	color: token('color.text.subtlest', N200),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `0 ${grid.twice().px}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedSearchResultsSectionTitle = styled.span({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	font: token('font.body.small'),
	fontWeight: token('font.weight.semibold'),
	textTransform: 'uppercase',
	color: token('color.text.subtlest', N200),
	boxSizing: 'border-box',
	padding: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultSectionTitleBadgeContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: grid.px,
	fontWeight: token('font.weight.regular'),
});
