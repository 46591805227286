import React, { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import FilterIcon from '@atlaskit/icon/core/filter';
import { Box, Inline, xcss } from '@atlaskit/primitives';

import { ApplicationModes } from '../../../../common/constants/application-modes';
import { CommandIcon } from '../../../../common/constants/icons/command';
import { ControlIcon } from '../../../../common/constants/icons/control';
import { ReturnIcon } from '../../../../common/constants/icons/return';
import { ProductKeys } from '../../../../common/constants/products';
import { useAppContext } from '../../../../common/ui/app-context';
import { ProductIcon } from '../../../../common/ui/product-icon';
import { SearchFooter } from '../../../../common/ui/quick-find/search-dialog-footer';
import { type State } from '../../../../common/utils/quick-find/state';
import ShowIfEligible from '../../../../controllers/application-modes';
import { type QuickFindFooterEvent } from '../../../../controllers/quick-find/utils';
import { useInputQuery, usePrimaryProduct } from '../../../../controllers/store';

import { messages } from './messages';

type Props = {
	state: State;
	onClick: (props: QuickFindFooterEvent) => void;
};

const titleStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

export const SearchPagePrimaryProductLink = ({ state, onClick }: Props) => {
	const intl = useIntl();

	const { generateSearchUrl, queryParams } = useAppContext();

	const [inputQuery] = useInputQuery();
	const [primaryProduct] = usePrimaryProduct();

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onClick({ event, state });
		},
		[onClick, state],
	);

	const title = useMemo(() => {
		if (primaryProduct === 'confluence') {
			return (
				<Box xcss={titleStyles}>
					{intl.formatMessage(messages.filterByConfluence, {
						confluenceIcon: <ProductIcon product={ProductKeys.Confluence} size="small" />,
					})}
				</Box>
			);
		} else {
			return null;
		}
	}, [primaryProduct, intl]);

	const rightMetadata = useMemo(
		() => (
			<Inline alignBlock="center" space="space.100">
				<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
					{window.navigator.userAgent.includes('Mac') ? <CommandIcon /> : <ControlIcon />}
				</ShowIfEligible>
				<ReturnIcon />
			</Inline>
		),
		[],
	);

	const url = generateSearchUrl({
		...queryParams,
		product: primaryProduct,
		text: inputQuery,
	});

	return (
		<SearchFooter
			icon={
				<FilterIcon
					label="" // Icon is only for display purposes and this footer button purpose is in the title
					color="currentColor"
				/>
			}
			title={title}
			rightMetadata={rightMetadata}
			url={url}
			onResultClicked={onResultClicked}
		/>
	);
};
