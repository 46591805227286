import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	quickFindNoContentHeading: {
		id: 'search_dialog_no_content_heading',
		defaultMessage: 'Seek and you shall find',
		description:
			'Header of prompt to begin search journey if no recent content results are rendered',
	},

	quickFindNoContentBody: {
		id: 'search_dialog_no_content_body',
		defaultMessage:
			"Search or ask Rovo what you're looking for in your Atlassian and integrated products",
		description:
			'Body text of prompt to begin search journey if no recent content results are rendered',
	},
});
