import React, { Suspense } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import { messages } from './messages';

const Image = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/search-error-image" */ './image'
		),
);

const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 'space.200',
	color: 'color.text',
});

const imageFallbackStyles = xcss({
	width: '124px',
	height: '124px',
});

const headingStyles = xcss({
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	marginTop: 'space.100',
});

const bodyStyles = xcss({
	marginTop: 'space.100',
	fontSize: 'font.body',
});

export const LandingScreen = () => {
	return (
		<Box xcss={containerStyles}>
			<Suspense fallback={<Box xcss={imageFallbackStyles} />}>
				<Image />
			</Suspense>
			<Box xcss={headingStyles}>
				<FormattedMessage {...messages.quickFindNoContentBody} />
			</Box>
			<Box xcss={bodyStyles}>
				<FormattedMessage {...messages.quickFindNoContentHeading} />
			</Box>
		</Box>
	);
};
