import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { VerifiedTeamIcon } from '@atlassian/people-teams-ui/verified-team-icon';
import { SearchResultContent, type SearchResultContentProps } from '@atlassian/search-dialog';
import { type ScreenType } from '../../common/analytics';
import { ResultAvatar } from '../../common/result-avatar';
import { type SearchResult } from '../product-router/product/result-types';

type ExtendedSearchResult = {
	isVerified?: boolean;
};

interface DefaultResultRendererArgs
	extends Pick<SearchResultContentProps, 'isCollapsed' | 'isKeyboardHighlighted'> {
	screen: ScreenType;
	searchResult: SearchResult<ExtendedSearchResult>;
}

/**
 * This function renders a search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 */
export const defaultResultRenderer = ({
	isCollapsed,
	isKeyboardHighlighted,
	screen,
	searchResult,
}: DefaultResultRendererArgs) => {
	const icon = React.isValidElement(searchResult.icon) ? (
		searchResult.icon
	) : typeof searchResult.icon === 'string' ? (
		<ResultAvatar
			borderColor="transparent"
			src={searchResult.icon}
			appearance="square"
			size="xsmall"
			testId="result-avatar"
		/>
	) : searchResult.iconRenderer ? (
		searchResult.iconRenderer({ screen })
	) : (
		<ResultAvatar
			borderColor="transparent"
			src={searchResult.iconUrl}
			appearance="square"
			size="xsmall"
			testId="result-avatar"
		/>
	);

	const title = searchResult.isVerified ? (
		<Inline space="space.025" alignBlock="center">
			{searchResult.title}
			<VerifiedTeamIcon size="small" />
		</Inline>
	) : (
		searchResult.title
	);

	return (
		<SearchResultContent
			key={`item_${searchResult.id}`}
			isKeyboardHighlighted={isKeyboardHighlighted}
			title={title}
			meta={searchResult.meta}
			additionalMeta={searchResult.additionalMeta}
			isCollapsed={isCollapsed}
			icon={icon}
		/>
	);
};
