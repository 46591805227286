import { RefObject, useEffect } from 'react';

import {
	getInputSkeletonFocus,
	getInputSkeletonQuery,
	getInputSkeletonSelection,
	getSessionItem,
	removeInputSkeletonState,
	SEARCH_QUERY_KEY,
	setSessionItem,
} from '@atlassian/search-dialog';

export const getInitialQuery = () => getSessionItem(SEARCH_QUERY_KEY) || getInputSkeletonQuery();

export type InputSkeletonProps = {
	localQuery: string;
	inputRef?: RefObject<HTMLInputElement>;
};

export const useInputSkeleton = ({ localQuery, inputRef }: InputSkeletonProps) => {
	// Focus input if skeleton is focused
	useEffect(() => {
		const skeletonFocused = getInputSkeletonFocus();

		if (skeletonFocused) {
			const input = inputRef?.current;
			if (input) {
				input.focus();
				const { selectionStart, selectionEnd, selectionDirection } = getInputSkeletonSelection();
				input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
			}
		}

		removeInputSkeletonState();
	}, [inputRef]);

	// Push local query to session storage
	useEffect(() => {
		setSessionItem(SEARCH_QUERY_KEY, localQuery);
	}, [localQuery]);
};
